import React from "react"
import { useTranslation, Trans, useI18next } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"
import { Nick, Alex, Gian, Antonio, Gabry, Marco } from "../assets/team"
import { GatsbySeo } from "gatsby-plugin-next-seo"

export default function About() {
  const { languages, originalPath } = useI18next() // Get language info
  const { t, i18n } = useTranslation() // Get translation info

  const siteUrl = "https://arcanode.io/about/"

  const items = [
    {
      id: 1,
      image: Alex,
      name: "Alessandro Pasqualetti",
      role: t("about.team.ceo"),
    },
    {
      id: 2,
      image: Nick,
      name: "Nicholas Fialdini",
      role: t("about.team.cto"),
    },
    {
      id: 3,
      image: Gian,
      name: "Gianluca Livrieri",
      role: t("about.team.coo"),
    },
    {
      id: 4,
      image: Marco,
      name: "Marco Pini",
      role: t("about.team.design"),
    },
    {
      id: 5,
      image: Gabry,
      name: "Gabriele Bussu",
      role: t("about.team.business"),
    },
    {
      id: 6,
      image: Antonio,
      name: "Antonio Gabbriellini",
      role: t("about.team.support"),
    },
  ]

  return (
    <>
      <GatsbySeo
        title={t("about.seo.title", "About Us")}
        description={t(
          "about.seo.description",
          "Arcanode provides expertise and innovation for retail, wholesalers, and IT companies; it’s the secure and high-performing solution that empowers businesses without compromising on cost and quality."
        )}
        canonical={`${siteUrl}/${i18n.language}${originalPath}`}
        openGraph={{
          url: `${siteUrl}/${i18n.language}${originalPath}`,
          title: t("about.seo.title", "About Us"),
          description: t(
            "about.seo.description",
            "Driven by a combination of technique and creativity, we create IT solutions that benefit today’s market."
          ),
          images: [
            {
              url: `${siteUrl}/icons/icon-512x512.png`,
              width: 512,
              height: 512,
              alt: "Arcanode",
            },
          ],
          site_name: "Arcanode",
        }}
        twitter={{
          handle: "@arcanode",
          site: "@arcanode",
          cardType: "summary_large_image",
        }}
        languageAlternates={languages.map(lang => ({
          hrefLang: lang,
          href: `${siteUrl}/${lang}${originalPath}`,
        }))}
      />

      <div className="flex flex-col items-center justify-center pt-4">
        <div className="max-w-md">
          <div className="py-2">
            <p className="text-md uppercase pb-2">
              <strong>{t("about.story.title")}</strong>
            </p>
            <p className="text-xs uppercase max-w-md">
              <Trans i18nKey="about.story.paragraph1">
                Starting small, we've steadily grown by embracing{" "}
                <strong>innovation</strong>. We focus on using advanced
                technology to create impactful solutions in the rapidly evolving
                'IT' sector, addressing current challenges while preparing for
                future demands.
              </Trans>
            </p>
            <p className="text-xs uppercase max-w-md py-4">
              <Trans i18nKey="about.story.paragraph2">
                Committed to excellence, we proudly uphold the{" "}
                <strong>'Made in Italy'</strong> standard, delivering scalable,
                secure solutions that drive digital and virtual transformation
                globally.
              </Trans>
            </p>
          </div>
        </div>
        <div className="pt-2 w-full max-w-md">
          <p className="text-md uppercase py-6">
            <strong>{t("about.sub")}</strong>
          </p>
          <div className="grid md:grid-cols-3 grid-cols-2 gap-2 md:gap-4 py-2">
            {items.map(item => (
              <div
                key={item.id}
                className="flex flex-col text-center items-center justify-center"
              >
                <img
                  src={item.image}
                  alt={item.name}
                  className="md:w-full md:h-full object-cover h-28 w-28 rounded-md p-2"
                />
                {/* <div className=" mb-2 rounded-2xl">{item.image}</div> */}
                <p className="text-xs uppercase">
                  <b>{item.role}</b>
                </p>
                <p className="text-xxs uppercase">{item.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
